<style lang="scss" scoped>
#nav {
  width: inherit;
  height: 48px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-position-y: 0;
  transition: 100ms;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  background-color: rgba(black, 0.1);
  backdrop-filter: blur(4px);
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient($primary, $primary-dark);
    border-bottom: solid 1px $secondary-dark;
    z-index: -1;
    transition: opacity 350ms, transform 350ms;
  }

  &.blend {
    color: #ffffff;
    position: static;
    &:before {
      opacity: 0;
      transform: translateY(100%);
    }
    .nav-link {
      &:hover,
      &:active,
      &.active,
      &.router-link-active {
        color: $primary;
        &:before {
          display: none;
        }
      }
    }
  }

  .nav-bar {
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .social-links {
      margin-left: auto;
      display: flex;
      .social-link {
        color: inherit;
        //text-indent: -99999px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .nav-brand {
      margin-right: auto;
      width: 48px;
      position: absolute;
      cursor: pointer;
      transition: 150ms;
      &:hover {
        color: white;
      }
      @media screen and (min-device-width: 800px) {
        position: static;
        left: 0;
        //transform: translateX(-50%);
      }
    }

    .nav-burger {
      z-index: 99;
      @media screen and (min-device-width: 800px) {
        display: none;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
</style>

<template>
  <!-- Responsive nav bar -->
  <nav id="nav" :class="$store.getters['nav/getBlend'] ? '' : 'blend'">
    <ul class="nav-bar">
      <li class="nav-burger"><btn-burger /></li>
      <li class="nav-brand" @click="$store.dispatch('swiper/slideTo', 0)">
        <logo-small />
      </li>
      <nav-link index="1">Patrol</nav-link>
      <nav-link index="2">Food</nav-link>
      <nav-link index="3">Drinks</nav-link>
      <nav-link index="4">Place</nav-link>
      <nav-link :to="{ name: 'Booking' }">Booking</nav-link>
      <nav-link href="https://deliveroo.fr/fr/menu/paris/6eme-odeon/patrol"
        >Delivery</nav-link
      >
      <nav-link index="5">Contact</nav-link>
      <li class="social-links">
        <a
          class="social-link hide-text"
          href="https://www.instagram.com/patrol_paris"
          target="_blank"
          rel="noopener"
          ><icon-instagram
        /></a>
        <a
          class="social-link hide-text"
          href="https://www.facebook.com/patrolparis"
          target="_blank"
          rel="noopener"
          ><icon-facebook
        /></a>
      </li>
    </ul>
    <!-- Mobile nav menu with clipped background -->
    <!--    <nav-menu />-->
  </nav>
</template>

<script>
// import { defineAsyncComponent } from "vue";
import NavLink from "@/views/nav/NavLink";

export default {
  name: "NavBar",
  components: {
    // NavMenu: defineAsyncComponent(() => import("@/views/nav/NavMenu")),
    NavLink
  }
};
</script>
